
import { Component, Prop } from 'vue-property-decorator';
import tasqsListModule from '@/store/modules/tasqsListModule';
import { mixins } from 'vue-class-component';
import uniqid from 'uniqid';
import draggable from 'vuedraggable';
import { Debounce } from 'vue-debounce-decorator';

  @Component({
    components: {
      draggable,
    },
  })
export default class ChecklistComponent extends mixins() {
@Prop({ type: Array, default: () => [] }) checklistData!: any;

@Prop({ type: Boolean, default: false }) hideAddNewItem!: boolean;

@Prop({ type: Boolean, default: false }) showCheckbox!: boolean;

@Prop({ type: Boolean, default: false }) hideAddRemoveItem!: boolean;

@Prop({ type: Boolean, default: false }) hideItemBackground!: boolean;

checklist: any = []

  newItemText = ''

  hoverItemId = ''

  created() {
    const data = JSON.parse(JSON.stringify(this.checklistData));
    this.checklist = data.map((item) => ({
      ...item,
      isHovering: false,
    }));
  }

  get isWorkTicketView() {
    return this.tasqListLevel === 'WorkTicket';
  }

  get isPadView() {
    return this.tasqListLevel === 'Pad';
  }

  get tasqListLevel() {
    return tasqsListModule.tasqListLevel;
  }

  mounted() {
    this.autoResizeTextarea()
  }

  autoResizeTextarea() {
    this.$nextTick(() => {
      const elements = this.$refs;
      if (elements && Object.keys(elements)) {
        Object.keys(elements).forEach((key) => {
          if (elements[key] && elements[key]![0]) {
            const target = elements[key]![0];
            target.style.height = 'auto';
            target.style.height = `${target.scrollHeight}px`;
          }
        });
      }
    });
  }

  addItemToList() {
    if (!this.newItemText) {
      return;
    }
    this.checklist.push({
      id: uniqid(), title: this.newItemText, checked: false, isHovering: false,
    });
    this.newItemText = '';
    this.$emit('update-checklist', this.checklist);
    this.autoResizeTextarea()
  }

  updateHoverItem(item) {
    this.hoverItemId = item.id;
  }

  removeItemFromList(item) {
    this.checklist = this.checklist.filter((step) => item.id !== step.id);
    this.$emit('update-checklist', this.checklist);
  }

  updateList() {
    this.$emit('update-checklist', this.checklist);
  }

  @Debounce(2000)
  updateListText(e) {
    e.target.style.height = 'auto';
    e.target.style.height = `${e.target.scrollHeight}px`;
    this.$emit('update-checklist', this.checklist);
  }
}

